<template lang="pug">
  v-theme-provider(:dark="dark")
    div
      base-img(
        :src="require('@/assets/logo_footer.png')"
        contain
        max-width="144"
        min-height="78"
        width="100%"
      )

      base-title(
        size="body-1"
        space="4"
        title="La armonía del ser y la naturaleza"
        weight="regular"
      )

      base-body
        span(class="white--text body-2")
          | {{ $t('weare.cosDescr') }}

      base-btn(:to="{ name: 'Conocenos' }" class="mb-12" color="white" outlined)
        | Conócenos

</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
  }
</script>
