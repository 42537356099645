import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import Vue2Filters from 'vue2-filters'
import VueYoutube from 'vue-youtube'
import './plugins'
import AOS from 'aos'
import 'aos/dist/aos.css'


Vue.config.productionTip = false

Vue.use(require('vue-moment'))
Vue.use(Vue2Filters)
Vue.use(VueYoutube)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  mounted() {
    AOS.init()
  }
}).$mount('#app')
