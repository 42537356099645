import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/layout/home/Index.vue'
import Thanks from '../views/thanks.vue'
import Tyc from '../views/tyc.vue'
import Tierra from '../components/Somoshuella/earthday.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: 'restauración',
        name: 'Restauración',
        component: () => import('@/views/restauracion/Index.vue'),
        meta: { src: require('@/assets/restauracion.jpg') },
      },
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/home/Index.vue'),
      },
      {
        path: 'vivero',
        name: 'Vivero',
        component: () => import('@/views/vivero/Index.vue'),
        meta: { src: require('@/assets/vivero.jpg') },
      },
      {
        path: 'personas',
        name: 'Personas',
        component: () => import('@/views/personas/Index.vue'),
        meta: { src: require('@/assets/personas.jpg') },
      },
      {
        path: 'conocenos',
        name: 'Conocenos',
        component: () => import('@/views/conocenos/Index.vue'),
        meta: { src: require('@/assets/personas.jpg') },
      },
      {
        path: 'empresas',
        name: 'Empresas',
        component: () => import('@/views/empresas/Index.vue'),
        meta: { src: require('@/assets/empresas.jpg') },
      },
      {
        path: 'privacidad',
        name: 'privacidad',
        component: () => import('@/views/privacidad.vue'),
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('@/views/team.vue'),
      },
      {
        path: '/blog',
        name: 'blog',
        component: () => import('@/views/blog/Index.vue'),
      },
      {
        path: 'sincronizate',
        name: 'Sincronizate',
        component: () => import('@/views/sincronizate/Index.vue'),
        meta: { src: require('@/assets/news.jpg') },
      },
      {
        path: 'ecoexperiencia',
        name: 'Ecoexperiencia',
        component: () => import('@/views/ecoexperiencia/Index.vue'),
      },
      {
        path: 'contact-us',
        name: 'Contacto',
        component: () => import('@/views/contact-us/Index.vue'),
        meta: { src: require('@/assets/contacto.jpg') },
      },
      {
        path: 'confirmacionBanco',
        name: 'confirmacionBanco',
        component: () => import('@/views/confirmacion/Index.vue'),
      },
      {
        path: 'misarboles',
        name: 'misarboles',
        component: () => import('@/views/misarboles/Index.vue'),
      },
      {
        path: 'misarboles/:id',
        name: 'misarbolesperfil',
        component: () => import('@/views/misarboles/Usuario.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/rte',
        name: 'rte',
        component: () => import('@/views/rte.vue'),
        meta: { src: require('@/assets/news.jpg') },
      },
    ],
  },
  { path: '/thanks', name: 'thanks', component: Thanks },
  { path: '/terminosycondiciones', name: 'tyc', component: Tyc },
  { path: '/diadelatierra', name: 'tierra', component: Tierra },
  
  { path: '*', redirect: 'home' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes,
})

export default router
