// Automatically loads and bootstraps files
// in the "./src/components/base" folder.

// Imports
import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context('@/components/base', true, /\.vue$/)

for (const file of requireComponent.keys()) {
  const componentConfig = requireComponent(file)
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))

  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig)
}


// otra forma
// import Vue from 'vue'
// import upperFirst from 'lodash/upperFirst'
// import camelCase from 'lodash/camelCase'

// const requireComponent = require.context('@/components/base', true, /.vue$/)

// const app = Vue

// for (const file of requireComponent.keys()) {
//   const componentConfig = requireComponent(file)
//   const name = file
//     .replace(/index.js/, '')
//     .replace(/^\.\//, '')
//     .replace(/\.\w+$/, '')
//   const componentName = upperFirst(camelCase(name))

//   app.component(`Base${componentName}`, componentConfig.default || componentConfig)
// }

// import upperFirst from 'lodash/upperFirst'
// import camelCase from 'lodash/camelCase'

// const requireComponent = import.meta.ROOT_URL + '/@/components/base'

// const components = requireComponent.keys().reduce((acc, file) => {
// const componentConfig = requireComponent(file)
// const name = file
// .replace(/index.js/, '')
// .replace(/^\.\//, '')
// .replace(/.\w+$/, '')
// const componentName = upperFirst(camelCase(name))

// acc[`Base${componentName}}`] = componentConfig.default || componentConfig
// return acc
// }, {})

// export default {
// install(app) {
// Object.entries(components).forEach(([name, component]) => {
// app.component(name, component)
// })
// }
// }





