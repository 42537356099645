<template lang="pug">
div
  base-info-card(
    :title="title"
    :subtitle="subtitle"
    space="4"
    color="primary"
  )
  
      v-form(v-model='valid', ref='form', lazy-validation='')
        v-text-field(outlined label='Nombre', v-model='user.nombre', :rules='user.nameRules', required='')
        v-text-field(outlined label='Apellido', v-model='user.apellido', required='')
        v-text-field(outlined label='Organización', v-model='user.organizacion')
        v-text-field(outlined label='E-mail', v-model='user.email', :rules='user.emailRules', required='')
        v-text-field(outlined label='Celular', v-model='user.telefono', required='')
        v-text-field(class="mb-6" auto-grow shaped rows="3" outlined single-line label='Comentario', v-model='user.comentario', required='')
        v-btn(v-on:click='send()', :disabled='!valid' color="secondary")
          | Enviar
      v-dialog(v-model="dialog" width="500")
        v-card
          v-card-title(class="text-h5 grey lighten-2")
            | pronto nos pondremos en contacto 
            | Gracias por escribirnos!
</template>

<script>
  import axios from 'axios'

  import config from '../../services'

  export default {
    name: 'BaseForm',

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },

    data () {
      return {
        dialog: false,
        valid: false,
        show: true,
        user: {
          nombre: '',
          nameRules: [
            (v) => !!v || 'Nombre es requerido',
          ],
          apellido: '',
          organizacion: '',
          email: '',
          emailRules: [
            (v) => !!v || 'Email es requerido',
            (v) => /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(v) || 'debe ser valido',
          ],
          comentario: '',
        },
      }
    },
    methods: {
      send () {
        if (this.$refs.form.validate()) {
          // eslint-disable-next-line no-unused-expressions
          axios.post(config.server.concat('/contacto'),
            {
              nombre: this.user.nombre,
              apellido: this.user.apellido,
              organizacion: this.user.organizacion,
              email: this.user.email,
              telefono: this.user.telefono,
              comentario: this.user.comentario,
            }
            ), this.dialog = true, this.$refs.form.reset()
        }
      },
    },
    computed: {
      isFormValid() {
        return this.valid
      }
    },
  }
</script>
