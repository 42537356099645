import axios from 'axios'

// PRO
const URLSOLO = 'https://kimu.amatea.org/'
const URL = 'https://kimu.amatea.org/amateaOrg'
const URLAPP = 'https://kimu.amatea.org/amateaApp'
const URLDENDROS = 'https://kimu.amatea.org/dendros2/'
const URLWEB = 'https://kimu.amatea.org/amateaWeb/'

// DEV
// const URL = 'http://localhost:8081/amateaOrg'
// const URLAPP = 'http://localhost:8081/amateaAPP'
export const HTTPWEB = axios.create({
  baseURL: URLWEB,
  headers: {
    'Content-Type': 'application/json',
  // eslint-disable-next-line
    'Authorization': 'Bearer ' + window.localStorage.token,
  },
})

export const HTTPSOLO = axios.create({
  baseURL: URLSOLO,
  headers: {
    'Content-Type': 'application/json',
  // eslint-disable-next-line
    'Authorization': 'Bearer ' + window.localStorage.token,
  },
})

export const HTTPDENDROS = axios.create({
  baseURL: URLDENDROS,
  headers: {
    'Content-Type': 'application/json',
  // eslint-disable-next-line
    'Authorization': 'Bearer ' + window.localStorage.token,
  },
})

// se conecta a misiembra
export const HTTP = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
  // eslint-disable-next-line
    'Authorization': 'Bearer ' + window.localStorage.token,
  },
})

export const HTTP2 = axios.create({
  // baseURL: 'https://kimu.amatea.org/amateaApp',
  baseURL: URLAPP,
  headers: {
    'Content-Type': 'application/json',
  // eslint-disable-next-line
    'Authorization': 'Bearer ' + window.localStorage.token,
  },
})

const dev = {
  server: URL,
}

export default dev
