import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true, // login
  plugins: [
    createPersistedState(), // login
  ],
  state: {
    token: null, // login
    user: null, // login
    isUserLoggedIn: false, // login
    arboles: 1,
    descripcionPlan: 'Siembra',
    personasXPaquete: 0,
    datePlan: '',
    order: {
      additionalValues: {
        TX_VALUE: {
          valu: 0,
        },
      },
    },

    // huella
    cocinar: [
      { No: 'Electricidad', value: 3 },
      { No: 'Gas', value: 1 },
      { No: 'Las dos', value: 2 },
    ],
    iluminacion: [
      { No: 'Ahorrador', value: 3 },
      { No: 'Led', value: 1 },
      { No: 'Los dos tipos', value: 2 },
    ],
    desconectas: [
      { No: 'Si', value: 1 },
      { No: 'No', value: 2 },
    ],
    tipo: [
      { No: 'Caminas', value: 1 },
      { No: 'Bicicleta', value: 2 },
      { No: 'Motocicleta', value: 5 },
      { No: 'Motocicleta eléctrica', value: 4 },
      { No: 'Automóvil', value: 7 },
      { No: 'Automóvil eléctrica', value: 6 },
      { No: 'Público', value: 3 },
    ],
    frecuencia: [
      { No: 'Nunca', value: 1 },
      { No: 'Una vez al año', value: 2 },
      { No: 'Dos veces al año', value: 3 },
      { No: 'Tres veces al año', value: 4 },
      { No: 'Más de tres veces al año', value: 5 },
    ],
    alimentacion: [
      { No: 'Omnivora', value: 3 },
      { No: 'Vegana', value: 1 },
      { No: 'Vegetariana', value: 2 },
    ],
    ropa: [
      { No: 'Natural (Algodón, Seda)', value: 1 },
      { No: 'Sintético (Poliester, rayón y otros)', value: 3 },
      { No: 'Te da igual', value: 2 },
    ],
    vivienda: [
      { No: 'Ecológicas (barro, madera)', value: 1 },
      { No: 'Tendinoso, Prefabricada', value: 2 },
      { No: 'Ladrillo y Cemento', value: 3 },
    ],
    celular: [
      { No: 'Cada año', value: 4 },
      { No: 'Cada dos años', value: 3 },
      { No: 'Más de dos años', value: 1 },
    ],
    bosque: [
      { No: 'Cada semana', value: 1 },
      { No: 'Cada mes', value: 2 },
      { No: 'Cada año', value: 3 },
      { No: 'Nunca', value: 4 },
    ],
    parques: [
      { No: 'Si', value: 2 },
      { No: 'No', value: 3 },
      { No: 'Vivo en el campo', value: 1 },
    ],
    reciclas: [
      { No: 'Si', value: 1 },
      { No: 'No', value: 2 },
    ],

    p1: null,
    p2: null,
    p3: null,
    p4: null,
    p5: null,
    p6: null,
    p7: null,
    p8: null,
    p9: null,
    p10: null,
    p11: null,
    totalHuella: 23,

    // Gift

    gift: [
      { motive: 'Amistad', value: 'amistad' },
      { motive: 'Cumpleaños', value: 'cumpleaños' },
      { motive: 'Partida de un ser querido', value: 'treebuto' },
      { motive: 'Matrimonio', value: 'matrimonio' },
      { motive: 'Nacimiento', value: 'nacimiento' },
      { motive: 'Fecha especial', value: 'fechaespecial' },
    ],

    gifter: '',
    emailGifter: '',
    motivo: '',

  },
  mutations: {
    // compra de arboles una sola vez
    increment () {
      if (this.state.arboles <= 100) {
        this.state.arboles++
      }
    },
    decrement () {
      if (this.state.arboles > 1) {
        this.state.arboles--
      }
    },
    changeName (state, newName) {
      state.descripcionPlan = newName
    },
    changedatePlan (state, date) {
      state.datePlan = date
    },

    // para login
    setToken (state, token) {
      state.token = token
      if (token) {
        state.isUserLoggedIn = true
      } else {
        state.isUserLoggedIn = false
      }
    },
    setUser (state, user) {
      state.user = user
    },
  },
  getters: {
    // compra de arboles una sola vez
    valu: (state) => {
      // eslint-disable-next-line no-return-assign
      return state.order.additionalValues.TX_VALUE.valu = state.arboles * 26000
    },
    personasXPaquete (state) {
      if (state.descripcionPlan === 'Básico') {
        return 30
      } else if (state.descripcionPlan === 'Restaura') {
        return 1
      } else if (state.descripcionPlan >= 13 && state.descripcionPlan <= 15) {
        return 2
      } else if (state.descripcionPlan >= 16 && state.descripcionPlan <= 20) {
        return 3
      } else if (state.descripcionPlan >= 21) {
        return 4
      } else {
        return 5
      }
    },
    descripcionPlan: (state) => {
      if (state.arboles >= 1 && state.arboles <= 10) {
        // eslint-disable-next-line no-return-assign
        return state.descripcionPlan = 'Basico'
      } else if (state.arboles >= 11 && state.arboles <= 20) {
        // eslint-disable-next-line no-return-assign
        return state.descripcionPlan = 'Restaura'
      } else if (state.arboles >= 21) {
        // eslint-disable-next-line no-return-assign
        return state.descripcionPlan = 'Sincronizate'
      }
    },
    totalHuella: (state) => {
      // eslint-disable-next-line no-return-assign
      return state.totalHuella = 300
      // if (sum < 3) {
      //   // eslint-disable-next-line no-return-assign
      //   return state.totalHuella = 100
      // } else {
      //   // eslint-disable-next-line no-return-assign
      //   return state.totalHuella = 100000
    },
  },
  actions: {
    setdescripcionPlan ({ commit }, newName) {
      commit('changeName', newName)
    },
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
  },
})

export default store
