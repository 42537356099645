<template lang="pug">
v-app
  home-app-bar
  home-view
  home-footer
</template>

<script>
  export default {
    name: 'HomeLayout',

    components: {
      HomeAppBar: () => import('./AppBar'),
      HomeFooter: () => import('./Footer'),
      HomeView: () => import('./View'),
    },
  }
</script>
