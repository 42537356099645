<template lang="pug">
router-view
</template>

<script>
  export default {
    name: 'Amatea',
    metaInfo: {
      title: 'Amatea organizacion socioambiental',
      titleTemplate: '%s | Amatea',
      htmlAttrs: { lang: 'es' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Sembramos la manera de cuidar el ser y la naturaleza | siembra de arboles nativos'},
        // Google / Schema.org markup:
        { itemprop: 'name', content: 'Amatea ← web amatea' },
        { itemprop: 'description', content: 'Sembramos la manera de cuidar el ser y la naturaleza | siembra de arboles nativos' },
        { itemprop: 'image', content: 'https://amatea.org/img/20anosweb.bdb40dc1.png' }
      ],
    },
  }
</script>
